<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="taitan"></Header>
    <BannerTitle title="taitan"></BannerTitle>
    <div class="ttBox">
      <div class="introduce wow slideInUp">
        <div class="con">
          <img src="@/assets/images/titan1.png" alt="" />
          <div>
            <span>{{ $t("tt.summary.tit1") }}</span>
            <p>{{ $t("tt.summary.tips11") }}</p>
            <p>{{ $t("tt.summary.tips12") }}</p>
          </div>
        </div>
        <div class="con">
          <img src="@/assets/images/titan2.png" alt="" />
          <div>
            <span>{{ $t("tt.summary.tit2") }}</span>
            <p>{{ $t("tt.summary.tips2") }}</p>
          </div>
        </div>
      </div>
      <Start class="wow slideInUp"></Start>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
import Start from "../../components/stars.vue";
export default {
  name: "taitan",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline,
    Start
  }
};
</script>

<style lang="scss">
.ttBox {
  width: 1300px;
  margin: auto;
  .introduce {
    width: 932px;
    margin: 62px auto;
    padding: 1px 0;
    .con {
      margin-bottom: 69px;
      clear: both;
      overflow: hidden;
      img {
        width: 72px;
        height: 72px;
        float: left;
        margin-right: 60px;
      }
      div {
        float: left;
        width: 800px;
        span {
          display: block;
          font-size: 24px;
          font-weight: 600;
          color: rgba(51, 51, 51, 1);
          line-height: 33px;
          margin-bottom: 14px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 40px;
        }
      }
    }
    .con:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
